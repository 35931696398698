import * as React from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import Navbar from "../components/navbar";
import styled from "@emotion/styled";
import { cleanDateString, cleanPhone } from "../utils";
import * as api from "../api";
import { GtagInit } from "../components/conversion";
import { useLocation } from "@reach/router";
import qs from "query-string";

const DEBUG = false;

interface Errors {
  name?: "is-invalid";
  phone?: "is-invalid";
  creation?: "is-invalid";
  incomes?: "is-invalid";
  hasDecennale?: "is-invalid";
  siren?: "is-invalid";
  zipcode?: "is-invalid";
}

const initialState = {
  status: "form" as "form" | "form2" | "loading" | "loading2" | "done",
  delayedId: "",
  siren: "",
  zipcode: "",
  city: "",
  delay: 10 * 60 * 1000,
  name: "",
  email: "",
  phone: "",
  company: "",
  activity: "",
  incomes: "",
  creation: "",
  hasDecennale: null as boolean | null,
  source: "vas_seo",
  query: {},
  errors: {} as Errors,
};

type Action =
  | { type: "LOADING" }
  | { type: "FORM" }
  | { type: "DONE"; result: { id?: string } }
  | { type: "ON_CHANGE"; id: string; value: string; checked: boolean }
  | { type: "SET_SOURCE"; query: any }
  | { type: "SET_ERRORS"; errors: Errors }
  | { type: "POST_ERROR" };

type State = typeof initialState;

const postLead = (params: any): Promise<{ id: string }> => {
  if (DEBUG) {
    console.log(JSON.stringify(params));
    return new Promise(resolve =>
      setTimeout(() => resolve({ id: "abc" }), 1000)
    );
  }
  return api.post("/lead", params);
};

const useDevis = () => {
  const [state, dispatch] = React.useReducer(
    (state: State, action: Action): State => {
      switch (action.type) {
        case "FORM":
          return { ...state, status: "form" };
        case "LOADING":
          if (state.delayedId) {
            return { ...state, status: "loading2" };
          } else {
            return { ...state, status: "loading" };
          }
        case "POST_ERROR":
          if (state.delayedId) {
            return { ...state, status: "form2" };
          } else {
            return { ...state, status: "form" };
          }
        case "DONE":
          if (state.delayedId) {
            return { ...state, status: "done" };
          } else {
            return {
              ...state,
              status: "form2",
              // keep track of delayed job id
              delayedId: action.result.id || state.delayedId,
              // next call will be immediate
              delay: 0,
            };
          }
        case "ON_CHANGE":
          const { id, value } = action;
          switch (id) {
            case "name":
              return {
                ...state,
                name: value,
                errors: { ...state.errors, name: undefined },
              };
            case "email":
              return { ...state, email: value.trim().toLowerCase() };
            case "phone":
              return {
                ...state,
                phone: cleanPhone(value),
                errors: { ...state.errors, phone: undefined },
              };
            case "company":
              return { ...state, company: value.toUpperCase() };
            case "activity":
              return { ...state, activity: value };
            case "incomes":
              return {
                ...state,
                incomes: value.toLowerCase(),
                errors: { ...state.errors, incomes: undefined },
              };
            case "creation":
              return {
                ...state,
                creation: value,
                errors: { ...state.errors, creation: undefined },
              };
            case "decennale-yes":
              return {
                ...state,
                hasDecennale: true,
                errors: { ...state.errors, hasDecennale: undefined },
              };
            case "decennale-no":
              return {
                ...state,
                hasDecennale: false,
                errors: { ...state.errors, hasDecennale: undefined },
              };
            case "siren":
              return {
                ...state,
                siren: value.replace(/\D/g, "").substring(0, 9),
                errors: { ...state.errors },
              };
            case "zipcode":
              return {
                ...state,
                zipcode: value.replace(/\D/g, "").substring(0, 5),
                errors: { ...state.errors, zipcode: undefined },
              };
            default:
              return state;
          }
        case "SET_SOURCE":
          const { query } = action;
          return {
            ...state,
            query,
            source: query && query.utm_campaign ? "vad_adwords" : "vad_seo",
          };
        case "SET_ERRORS":
          const { errors } = action;
          return { ...state, errors };
      }
    },
    initialState as State
  );
  // store query data to track source
  const location = useLocation();
  React.useEffect(() => {
    const query =
      (location.search && qs.parse(location.search)) ||
      JSON.parse(window.localStorage.getItem("q") || "null") ||
      null;
    window.localStorage.setItem("q", JSON.stringify(query));
    dispatch({
      type: "SET_SOURCE",
      query,
    });
  }, []);
  const validate = (state: State) => {
    const errors = [
      state.name.trim().length <= 4 ? "name" : null,
      state.phone.replace(/ /g, "").length != 10 ? "phone" : null,
      state.hasDecennale === null ? "hasDecennale" : null,
      state.incomes.trim().length == 0 ? "incomes" : null,
    ]
      .concat(
        state.delayedId ? [state.zipcode.length != 5 ? "zipcode" : null] : []
      )
      .filter(key => key !== null);
    return errors.length == 0 ? null : errors;
  };
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    dispatch({
      type: "ON_CHANGE",
      id,
      value: e.target.value,
      checked: e.target.checked,
    });
  };
  const setValue = (id: string, value: string) => {
    dispatch({
      type: "ON_CHANGE",
      id,
      value,
      checked: false,
    });
  };
  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.status == "loading" || state.status == "loading2") {
      return;
    }
    const errors = validate(state);
    if (errors) {
      return dispatch({
        type: "SET_ERRORS",
        errors: errors.reduce(
          (acc, key) => ({ ...acc, [key || ""]: "is-invalid" }),
          {}
        ),
      });
    }
    dispatch({ type: "LOADING" });
    const params = {
      name: state.name,
      phone: state.phone,
      company: state.company,
      activity: state.activity,
      creation: state.creation,
      email: state.email,
      has_decennale: state.hasDecennale,
      revenue: state.incomes,
      query: state.query || {},
      source: state.source || "vad_seo",
      // form 2
      delayedId: state.delayedId || undefined,
      delay: state.delayedId ? 0 : state.delay,
      siren: state.siren || (state.delayedId ? "en création" : "") || undefined,
      zipcode: state.zipcode || undefined,
    };
    postLead(params)
      .then(result => dispatch({ type: "DONE", result }))
      .catch(err => {
        console.log(err);
        dispatch({ type: "POST_ERROR" });
      });
  };
  return { state, dispatch, change, setValue, submit };
};

const FormContainer = styled.div`
  background-image: url("${(props: any) => props.image}");
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 70px);
  label {
    font-weight: bold;
  }
  .form-group {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .form-box {
    background-color: rgba(255, 255, 255, 0.95);
    max-width: 850px;
    padding: 8px;
    @media (min-width: 640px){
      padding: 16px;
    }
  }
  .form-column {
    max-width: 300px;
    margin: auto;
    padding: 0px 8px;
  }
`;

const Form = ({ children }) => {
  const data = useStaticQuery(graphql`
    query DevisFormImage {
      image: file(relativePath: { eq: "bg-chantier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 1024) {
            src
          }
        }
      }
    }
  `);
  return (
    <FormContainer
      className="container-fluid pt-2 pt-sm-5 mb-sm-4"
      image={data.image.childImageSharp.fluid.src}
    >
      <div className="container mt-5 px-0 px-sm-2">
        <div className="form-box mx-auto">{children}</div>
      </div>
    </FormContainer>
  );
};

const useDateInput = (onChange: (str: string) => void) => {
  const [lastKey, setLastKey] = React.useState(null);
  const onDown = e => setLastKey(e.which || e.keyCode);
  const onInput = e => {
    if (lastKey == 8) {
      // delete was pressed, do not reformat
      onChange(e.target.value);
      return;
    }
    onChange(cleanDateString(e.target.value));
  };
  return {
    onInput,
    onKeyDown: onDown,
    onChange: onInput,
  };
};

const Devis = (props: PageProps) => {
  const { state, setValue, change, submit, dispatch } = useDevis();
  const dateInput = useDateInput(s => setValue("creation", s));
  const disabled = state.status != "form" && state.status != "form2";
  React.useEffect(() => {
    if (state.status == "done") {
      window.location.assign("/devis-success");
    }
  }, [state.status]);
  return (
    <>
      <Navbar />
      <Form>
        <h1
          className="h3 text-danger text-center pt-3"
          style={{ textTransform: "uppercase" }}
        >
          Obtenir mon devis gratuitement
        </h1>
        {["form", "loading"].includes(state.status) ? (
          <>
            <p className="lead text-center px-4">
              Validez le formulaire et recevez jusqu'à trois devis
              gratuits,&nbsp;
              <strong>Attestation immédiate</strong>
            </p>
            <form onSubmit={submit} className="p-md-4 p-sm-2">
              <div className="d-md-flex justify-content-around">
                <div className="form-column flex-grow-1 mt-0">
                  <div className="form-group">
                    <label htmlFor="name">Prénom Nom :</label>
                    <input
                      className={"form-control " + state.errors.name}
                      type="text"
                      placeholder="Jean Gagne"
                      id="name"
                      value={state.name}
                      onChange={change}
                      disabled={disabled}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email :</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="jean.Gagne@gmail.com"
                      id="email"
                      value={state.email}
                      onChange={change}
                      disabled={disabled}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Téléphone :</label>
                    <input
                      type="tel"
                      className={"form-control " + state.errors.phone}
                      placeholder="01 02 03 04 05"
                      id="phone"
                      value={state.phone}
                      onChange={change}
                      disabled={disabled}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="company">Nom de votre société :</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="SARL Gagne"
                      id="company"
                      value={state.company}
                      onChange={change}
                      disabled={disabled}
                      required
                    />
                  </div>
                </div>
                <div className="form-column">
                  <div className="form-group">
                    <label htmlFor="activity">Activité principale :</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Plombier"
                      id="activity"
                      value={state.activity}
                      onChange={change}
                      disabled={disabled}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="incomes">Chiffre d'affaire annuel :</label>
                    <input
                      type="text"
                      className={"form-control " + state.errors.incomes}
                      placeholder="50000"
                      id="incomes"
                      value={state.incomes}
                      onChange={change}
                      disabled={disabled}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="creation">
                      Date de création (JJ/MM/AAAA) :
                    </label>
                    <input
                      type="text"
                      className={"form-control " + state.errors.creation}
                      placeholder="14/02/2001"
                      value={state.creation}
                      {...dateInput}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <div
                      className={
                        "form-control- border-0 bg-transparent px-0 py-0 " +
                        state.errors.hasDecennale
                      }
                    >
                      <span>Vous êtes déjà assuré&nbsp;?</span>
                      <div className="d-block mt-3 x-d-md-inline-block">
                        <label className="pl-3">
                          <input
                            type="radio"
                            id="decennale-yes"
                            onChange={change}
                            checked={state.hasDecennale === true}
                            disabled={disabled}
                          />{" "}
                          Oui
                        </label>
                        <label className="pl-3">
                          <input
                            type="radio"
                            id="decennale-no"
                            onChange={change}
                            checked={state.hasDecennale === false}
                            disabled={disabled}
                          />{" "}
                          Non
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear-fix text-center mt-3">
                <button
                  className="btn-action py-3"
                  type="submit"
                  disabled={disabled}
                >
                  Envoyer ma demande de devis
                </button>
              </div>
            </form>
          </>
        ) : ["form2", "loading2"].includes(state.status) ? (
          <>
            <p className="lead text-center px-4">
              Renseignez ces dernières informations et recevez jusqu’à trois
              devis gratuits.
            </p>
            <form onSubmit={submit} className="p-md-4 p-sm-2">
              <div className="d-md-flex justify-content-around">
                <div className="form-column flex-grow-1 mt-0">
                  <div className="form-group">
                    <label htmlFor="siren">
                      SIREN (si entreprise déjà crée) :
                    </label>
                    <input
                      type="text"
                      className={"form-control " + state.errors.siren}
                      placeholder=""
                      id="siren"
                      pattern="[0-9]{9}"
                      value={state.siren}
                      onChange={change}
                      disabled={disabled}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="zipcode">Code postal</label>
                    <input
                      id="zipcode"
                      type="text"
                      className={"form-control " + state.errors.zipcode}
                      disabled={disabled}
                      value={state.zipcode}
                      onChange={change}
                      pattern="[0-9]{5}"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="clear-fix text-center mt-3">
                <button
                  className="btn-action py-3"
                  type="submit"
                  disabled={disabled}
                >
                  Envoyer ma demande de devis
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="p-4"></div>
        )}
      </Form>
      <GtagInit />
    </>
  );
};

export default Devis;
